<template>
  <div>
    <b-row>
      <b-col md="6" offset-md="3">
        <div>
          <span
            >* Select Country and Drag Event Name Up or Down and click update
            sort order button to change sort order of banners.</span
          >
        </div>
        <hr />
        <b-form-group class="m-1" label="Country Name*">
          <v-select
            @input="getBannerList"
            :clearable="true"
            v-model="country_id"
            label="country_name"
            value="country_id"
            :reduce="(country) => country.country_id"
            placeholder="Select Country"
            :options="countryListArray"
          />
        </b-form-group>
        <b-card class="mt-1" no-body>
          <b-list-group flush>
            <draggable
              v-model="bannerItems"
              @start="drag = true"
              @end="drag = false"
            >
              <b-list-group-item
                v-for="(element, index) in bannerItems"
                :key="index"
              >
                <span> {{ element.event_name }}</span>

                <span
                  v-if="bannerItems.length > 1 && country_id && is_update"
                  @click="onRemove(index)"
                  class="float-right"
                >
                  <feather-icon icon="DeleteIcon" size="30"></feather-icon
                ></span>
              </b-list-group-item>
            </draggable>
            <b-button
              v-if="is_add"
              @click="getEventList"
              :disabled="country_id ? false : true"
              v-b-modal.modal-prevent-closing
              variant="link"
              >Add Banners</b-button
            >
          </b-list-group>
        </b-card>
        <b-button
          :disabled="country_id ? false : true"
          v-if="bannerItems.length > 1 && is_update"
          block
          @click="onUpdateSortOrder"
          variant="primary"
          >Update Sort Order</b-button
        >
      </b-col>
    </b-row>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id="modal-prevent-closing"
      ref="modal"
      title="Note : Event Large Image Will be fetched as banner "
      @ok="handleOk"
    >
      <form ref="form">
        <!-- <b-form-group label="Country Name">
          <v-select
            @input="getEventList"
            :clearable="false"
            v-model="country_id"
            label="country_name"
            value="country_id"
            :reduce="(country) => country.country_id"
            placeholder="Select Country"
            :options="countryListArray"
          />
        </b-form-group> -->
        <b-form-group label="Event">
          <v-select
            v-model="selectedEvent"
            multiple
            placeholder="Select Event"
            label="event_name"
            value="event_id"
            :options="eventListData"
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  GetEventListService,
  GetBannerListService,
  AddUpdateBannerService,
  GetCountryListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import { UserService } from "@/apiServices/storageService";
export default {
  data() {
    return {
      searchValue: null,
      country_id: null,
      isBusy: false,
      selectedEvent: [],
      countryListArray: [],
      bannerItems: [],
      eventListData: [],
      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
    };
  },
  components: {
    draggable,
    vSelect,
  },
  computed: {
    is_add() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "create");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_update() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "update");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_read() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "read");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.getBannerList();
    this.getCountryList();

    // this.getEventList();
  },
  methods: {
    async handleOk() {
      if (!this.selectedEvent.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please Select Event",
            icon: "EditIcon",
            variant: "danger",
          },
        });
        return;
      }
      for (let item1 of this.selectedEvent) {
        let obj = {
          event_id: item1.event_id,
          event_name: item1.event_name,
          order: null,
        };
        this.bannerItems.push(obj);
      }
      this.selectedEvent = [];
      this.onUpdateSortOrder();
    },
    async onRemove(index) {
      this.bannerItems.splice(index, 1);
      this.onUpdateSortOrder();
    },
    async onUpdateSortOrder() {
      try {
        let bannerObj = {
          country_id: this.country_id,
          bannerArray: [],
        };
        for (let [index, item] of this.bannerItems.entries()) {
          let obj = {
            event_id: item.event_id,
            order: index,
          };
          bannerObj.bannerArray.push(obj);
        }
        this.isBusy = true;

        const response = await AddUpdateBannerService(bannerObj);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    async getCountryList() {
      try {
        const response = await GetCountryListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.countryListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getBannerList() {
      try {
        this.isBusy = true;
        const response = await GetBannerListService({
          limit: 5000,
          country_id: this.country_id,
        });
        if (response.data.status) {
          this.bannerItems = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    async getEventList() {
      this.eventListData = [];
      try {
        const response = await GetEventListService({
          limit: 5000,
          country_id: this.country_id,
        });
        if (response.data.status) {
          this.eventListData = response.data.Records;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped></style>
